import { Script } from 'gatsby';
import React from 'react';

export const wrapPageElement = ({ element, props }) => (
    <>
        <Script
            strategy="post-hydrate"
            dangerouslySetInnerHTML={{
                __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MT5QQNW');
          `,
            }}
        />
        {/* // IpMeta script tag added to handle bot traffic */}
        <Script
            strategy="post-hydrate"
            src="https://ipmeta.io/plugin.js"
            onLoad={() => {
                const data = window.provideGtmPlugin({
                    gtmEventKey: 'ipmeta_loaded',
                    apiKey: '54974eaed3e2de7209fd6128783d52ca9828a142eb755a8250340aeeb7015419',
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push(data);
            }}
        />
        {element}

        <Script
            strategy="idle"
            type="text/javascript"
            src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
            data-domain-script="542bfc4d-e197-46dd-ae08-8e848945eb25"
        />
        <Script
            strategy="idle"
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
        <Script
            strategy="idle"
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        />
    </>
);
