export { wrapPageElement } from './gatsby-shared';

export const onClientEntry = () => {
    // Set local storage with any UTM params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');

    if (utmSource) {
        sessionStorage.setItem('ufUtmSource', utmSource);
    }

    if (utmMedium) {
        sessionStorage.setItem('ufUtmMedium', utmMedium);
    }

    if (utmCampaign) {
        sessionStorage.setItem('ufUtmCampaign', utmCampaign);
    }
};

export const onRouteUpdate = (_ref) => {
    if (_ref && _ref?.location) {
        //console.log(_ref.location);
        const pageData = _ref?.loadPageSync(_ref?.location?.pathname)?.json
            ?.data;
        //console.log(pageData);
        const isBrowser = typeof window !== 'undefined';
        const referrer = _ref.prevLocation
            ? `${_ref.prevLocation.hostname}${_ref.prevLocation.pathname}`
            : '';
        let targetAudiences = '';
        let targetSolutions = '';

        // Check if post or page and get audience and solutions
        if (pageData) {
            if (pageData.post || pageData.page) {
                const audiences = pageData.post
                    ? pageData.post?.audiences?.nodes
                    : pageData?.page?.audiences?.nodes;
                const solutions = pageData.post
                    ? pageData.post?.solutions?.nodes
                    : pageData?.page?.audiences?.solutions;

                if (audiences && audiences.length > 0) {
                    const audienceArray = audiences.map((item) => item.name);
                    targetAudiences = audienceArray.join(',');
                }

                if (solutions && solutions.length > 0) {
                    const solutionArray = solutions.map((item) => item.name);
                    targetSolutions = solutionArray.join(',');
                }
            }
        }

        if (isBrowser) {
            window.dataLayer = window.dataLayer || [];
            setTimeout(function () {
                window.dataLayer.push({
                    event: 'gatsby-route-change',
                    referrer,
                    targetAudiences,
                    targetSolutions,
                });
            }, 50);
        }
    }
};
